




























































































import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
@Component({
    components: { BackButton },
})
export default class AboutUsIndex extends Vue {
    supportEmailLink: string =
        "mailto:" + process.env.VUE_APP_APP_SUPPORT_EMAIL;
    twitterLink: string = process.env.VUE_APP_TWITTER_PAGE_URL;
    facebookLink: string = process.env.VUE_APP_FACEBOOK_PAGE_URL;
}
